















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ name: 'EditMenuDescriptionInput' })
export default class EditMenuDescriptionInput extends Vue {
  @Prop({
    type: String,
    default: null,
  }) readonly value!: string

  maxLength = 50

  created () {
    const maxLength = window.FEATURES?.max_color_rule_description_length
    maxLength && (this.maxLength = maxLength)
  }

  validateLength (input: string): boolean | string {
    if (input.length <= this.maxLength) {
      this.$emit('error', false)
      return true
    }
    this.$emit('error', true)
    return this.$t('common.validationErrors.maxLength', { to: this.maxLength }).toString()
  }
}
